/* External dependencies */
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

/* Local dependencies */
import ConsigneeDetails from '../../../components/consignee-details';
import Layout from '../../../components/layout';
import formatMessage from '../../../intl/formatMessage';

export default function VehicleDetailsPage({ data }) {
  const title = formatMessage('Consignee');

  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <ConsigneeDetails />
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
