/* External dependencies */
import { Div } from 'atomize';
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

/* Local dependencies */
import PrintIcon from '../../../svg/PrintIcon';
import PrimaryButton from '../../buttons/PrimaryButton';
import '../index.scss';

export interface PrimaryButtonPrintProps {
  textForBtn?: any;
  children?: any;
}

export default function PrimaryButtonPrint({
  children,
  textForBtn,
}: PrimaryButtonPrintProps) {
  const [isHovered, setIsHovered] = useState(false);
  const componentRef = useRef();
  const widthPrintIcon = 20;
  const heightPrintIcon = 18;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PrimaryButton
        onClick={handlePrint}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        size="lg"
      >
        {textForBtn}
        <PrintIcon
          width={widthPrintIcon}
          height={heightPrintIcon}
          style={{ marginLeft: '15px' }}
          className="print-icon--primary"
          fill={isHovered ? '#3366ff' : '#fff'}
        />
      </PrimaryButton>
      <Div d="none">
        <Div ref={componentRef} m={{ x: '15pt', y: '15pt' }}>
          {children}
        </Div>
      </Div>
    </>
  );
}
