/* Local dependencies */
import renderDate from '../common/renderDate';

export const fields = [
  {
    label: 'unloadedDate',
    key: 'unloadedToWarehouseDate',
    value: (consignee) => renderDate(consignee.unloadedToWarehouseDate),
    placeholder: 'unloadedDate',
  },
  {
    label: 'fromVehicle',
    key: 'vehiclePlateNumber',
    placeholder: 'vehiclePlateNumberPlaceholder',
  },

  {
    hasSeparator: true,
    key: 'warehouse',
    label: 'toWarehouse',
    placeholder: 'warehouse',
    value: (consignee) => consignee.warehouse?.name,
  },
  {
    key: 'cargoWeight',
    placeholder: 'cargoWeight',
    value: (consignee) => consignee.cargoWeight,
  },
  {
    label: 'slotsQuantity',
    key: 'slots',
    placeholder: 'slots',
    value: (consignee) => consignee.slots,
  },

  {
    key: 'products',
    placeholder: 'unloadtoWarehousePlaceholder',
    value: (vehicle) => vehicle.products.join(','),
  },
  {
    hasSeparator: true,
    key: 'entryRegistered',
    placeholder: 'entryRegistered',
  },
  {
    key: 'cargoRegistered',
    placeholder: 'cargoRegistered',
  },
  {
    key: 'cargoUnloaded',
    placeholder: 'cargoUnloaded',
  },
];
