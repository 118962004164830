/* External dependencies */
import { Container, Row, Div, Col } from 'atomize';
import * as Gatsby from 'gatsby';
import { FormattedMessage } from 'gatsby-plugin-react-intl';
import React, { useEffect } from 'react';
import * as ReactRedux from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import { isBrowser } from '../common/helpers';
import FormField from '../form/FormField';
import { rowVehicleStyle } from '../form/FormStyles';
import BackNav from '../nav/BackNav';
import ErrorPopup from '../popup/ErrorPopup';
import ActTemporaryStorageToPrint from '../print/ActTemporaryStorageToPrint';
import BasicButtonPrint from '../print/BasicButtonPrint';
import ConsigneePassToPrint from '../print/ConsigneePassToPrint';
import PrimaryButtonPrint from '../print/PrimaryButtonPrint';
import { PageSpinner } from '../spinner/Spinner';
import {
  getConsignee,
  resetConsigneeDetails,
  resetError,
} from './redux/actions';
import { ConsigneeState } from './redux/reducer';
import { fields } from './fields';
import { getUser } from '../user-details/redux/actions';

export default function ConsigneeDetails() {
  const dispatch = ReactRedux.useDispatch();

  const { loading, consignee, error } = ReactRedux.useSelector(
    (state: RootState): ConsigneeState => state.consignee,
  );
  const {
    currentUser: { sub: userId },
  } = ReactRedux.useSelector((state: RootState) => state.login);
  const { user } = ReactRedux.useSelector((state: RootState) => state.user);

  const errorMessage =
    error &&
    (error.code ? <FormattedMessage id={error.code} /> : error.message);

  const id: any = isBrowser && localStorage.getItem('consigneeId');

  useEffect(() => {
    dispatch(getConsignee(id));
    dispatch(getUser(userId));

    return () => {
      dispatch(resetConsigneeDetails());
    };
  }, []);

  const onClickBackNav = () => {
    Gatsby.navigate('/consignees');
  };

  const onResetError = () => {
    dispatch(resetError());
  };

  const rowStyle = {
    ...rowVehicleStyle,
    alignItems: 'baseline',
    borderTop: '1px solid #EDF1F7',
    paddingTop: '1rem',
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <Container>
      <Row m={{ y: '1rem' }}>
        <BackNav label={`${consignee?.name}`} onClick={onClickBackNav} />
      </Row>

      <Row w="100%" p={{ l: '1.5rem' }}>
        <Col size={{ xs: '12', md: '10', lg: '6' }}>
          <Div m={{ b: { xs: '40px', lg: '0' } }}>
            {fields?.map((field, key) => {
              const label = field.label
                ? formatMessage(field.label)
                : formatMessage(field.key);
              const placeholder = formatMessage(field.placeholder);
              const value = field.value
                ? field.value(consignee)
                : consignee && consignee[field.key];

              const inputType = typeof value === 'number' ? 'number' : 'text';

              const formField = (
                <FormField
                  bg={'input_filled_bg'}
                  colSize={'7'}
                  disabled={true}
                  label={label}
                  inputValue={value}
                  inputType={inputType}
                  inputName={field.label}
                  placeholder={placeholder}
                  tabIndex="1"
                />
              );

              if (field.hasSeparator) {
                return (
                  <Row style={rowStyle} key={key}>
                    {formField}
                  </Row>
                );
              }

              return (
                <Row
                  {...rowVehicleStyle}
                  align="baseline"
                  flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                  key={key}
                >
                  {formField}
                </Row>
              );
            })}
            <Row p={{ t: '1.75rem', b: '1.5rem' }}>
              <Col>
                <BasicButtonPrint
                  textForBtn={<FormattedMessage id="printCargoPass" />}
                  cloneChild
                >
                  <ConsigneePassToPrint consignee={consignee!} />
                </BasicButtonPrint>
              </Col>
            </Row>
            <Row>
              <Col>
                <PrimaryButtonPrint
                  textForBtn={
                    <FormattedMessage id="printTemporaryStorageAct" />
                  }
                >
                  <ActTemporaryStorageToPrint
                    organization={user?.organization?.name}
                    consignee={consignee!}
                  />
                </PrimaryButtonPrint>
              </Col>
            </Row>
          </Div>
        </Col>
      </Row>

      {error && (
        <Div>
          <ErrorPopup
            onSubmit={onResetError}
            submitText={<FormattedMessage id="close" />}
            title={<FormattedMessage id="errorOccurred" />}
          >
            {errorMessage}
          </ErrorPopup>
        </Div>
      )}
    </Container>
  );
}
